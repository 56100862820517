
export class StayDuration {
  constructor(etracker, data) {
    this.etracker = etracker;
    this.data = data;
    this.entryTime = Date.now();
    this._onBeforeUnload = this.onBeforeUnload.bind(this);
  }

  start() {
    this.entryTime = Date.now();
    window.addEventListener('beforeunload', this._onBeforeUnload, false);
  }

  end() {
    window.removeEventListener('beforeunload', this._onBeforeUnload, false);
    const data = this.getData();
    this.etracker.send(data);
  }

  getData() {
    const { etracker, data, entryTime } = this;
    return etracker.getData({
      ...data,
      duration: Date.now() - entryTime
    });
  }

  onBeforeUnload(event) {
    // event.preventDefault();
    // event.returnValue = '';
    const data = this.getData();
    const params = new URLSearchParams(data);
    // 发送数据
    if (navigator.sendBeacon && this.etracker.reportUrl) {
      navigator.sendBeacon(this.etracker.reportUrl, params);
    } else {
      console.error('sendBeacon not supported');
    }

    return false;
  }
};
