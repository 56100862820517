import { ajax } from './ajax';
import Cookies from 'js-cookie';
import { StayDuration } from './modules';
import { createUid } from './utils';
import VueTracker from './vue';

const version = '1.0.11';

const etracker = {
  GUID_KEY: 'etrackerguid',
  reportUrl: null,
  baseInfo: {}
};

etracker.create = function({ reportUrl, baseInfo, heartbeat }) {
  etracker.reportUrl = reportUrl;
  etracker.baseInfo = baseInfo;

  heartbeat && etracker.addHeartbeat(heartbeat);
};

etracker.getData = function(data) {
  const location = window.location;
  return {
    host: location.host,
    url: location.href.replace(location.protocol + '//' + location.host, ''),
    ts: parseInt(Date.now() / 1000),
    ...etracker.baseInfo,
    ...data
  };
};

etracker.stayDuration = function(data) {
  const entryTime = Date.now();
  window.addEventListener('beforeunload', function(event) {
    // event.preventDefault();
    // event.returnValue = '';

    data = etracker.getData({ ...data, duration: Date.now() - entryTime });
    const params = new FormData();
    Object.keys(data).filter(key => !!data[key]).forEach(key => {
      params.append(key, data[key]);
    });
    // const params = new URLSearchParams(data);
    // 发送数据
    if (navigator.sendBeacon && etracker.reportUrl) {
      navigator.sendBeacon(etracker.reportUrl, params);
    } else {
      console.error('sendBeacon not supported');
    }

    return false;
  });
};

etracker.send = async function(data) {
  data = etracker.getData(data);
  console.log('etracker send: ', data, etracker.options);

  etracker.reportUrl && await ajax.post(etracker.reportUrl, data);
  return true;
};

etracker.destroy = function() {
  etracker.removeHeartbeat();
};

let timer;
etracker.addHeartbeat = function({ interval, data }) {
  timer = setInterval(() => {
    etracker.send(data);
  }, interval || 5000);
};

etracker.removeHeartbeat = function() {
  timer && clearInterval(timer);
};

// let isGotGuid = false;
etracker.getGuid = function() {
  let guid = Cookies.get(etracker.GUID_KEY);
  // if (!isGotGuid && guid) {
  //   isGotGuid = true;
  //   return guid;
  // }

  if (getRefer().indexOf(window.location.host) === -1) {
    guid = createUid();
    Cookies.set(etracker.GUID_KEY, guid);
  } else {
    if (!guid) {
      guid = createUid();
      Cookies.set(etracker.GUID_KEY, guid);
    }
  }
  return guid;
};

function getRefer() {
  var ref = '';
  if (document.referrer.length > 0) {
    ref = document.referrer;
  } else {
    try {
      if (ref.length === 0 && opener.location.href.length > 0) {
        ref = opener.location.href;
      }
    } catch {
    }
  }
  return ref;
}

function getWebBodySize() {
  const resources = performance.getEntries();
  let totalBytes = 0;
  for (let i = 0; i < resources.length; i++) {
    const resource = resources[i];
    if (resource.encodedBodySize) {
      totalBytes += resource.encodedBodySize;
    }
  }
  return totalBytes;
}

export default etracker;
export {
  version,
  StayDuration,
  getWebBodySize,
  etracker,
  VueTracker
};
