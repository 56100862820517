export default {
  install(Vue, { etracker } = {}) {
    Vue.directive('track', {
      mounted(el, { arg, value, modifiers }, vnode) {
        console.log('mounted: ', el, arg, value, modifiers, vnode);

        el.trackSendHandler = () => {
          if (etracker) {
            etracker.send(value);
          }
        };

        if (arg) {
          if (isValidEvent(arg)) {
            el.addEventListener(arg, el.trackSendHandler, false);
          }
        } else {
          el.trackSendHandler();
        }
      },
      // dom 更新才会触发， 指令的数据更新，并不会触发
      updated(el, binding, vnode) {
        console.log('updated: ', el, binding, vnode);
      },
      beforeUnmount(el, { arg }, vnode) {
        console.log('unmounted: ', el, arg, vnode);
        el.removeEventListener(arg, el.trackSendHandler, false);
      }
    });
  }
};

function isValidEvent(eventName) {
  // 创建一个空的 <div> 元素，用于测试事件名
  const div = document.createElement('div');
  // 将事件名转换为小写，并添加前缀 "on"
  const eventFullName = 'on' + eventName.toLowerCase();
  // 使用 hasOwnProperty 方法检查事件名是否为标准 DOM 事件
  return eventFullName in div;
}
